import * as tslib_1 from "tslib";
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions } from '@angular/http';
var OrgNewOnboardService = /** @class */ (function (_super) {
    tslib_1.__extends(OrgNewOnboardService, _super);
    function OrgNewOnboardService(http, httpP) {
        var _this = _super.call(this, 'latipay-onboard', http) || this;
        _this.http = http;
        _this.httpP = httpP;
        _this.embeddedStr = '&embedded=latipay-onboard-add-on,latipay-onboard-contacts,latipay-onboard-bank-accounts,latipay-onboard-persons,merchant-onboarding';
        return _this;
    }
    OrgNewOnboardService.prototype.ngOnInit = function () {
    };
    OrgNewOnboardService.prototype.getAllOnboarding = function (searchCondtion, token, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.get(this.url + "/get-all/?" + paging.getPagingStr() + (searchCondtion + userIdStr)).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.get = function (id) {
        return this.http.get(this.url + "/" + id + "?" + this.embeddedStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.complete = function (id) {
        return this.http.get(this.url + "/complete/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.reject = function (id) {
        return this.http.get(this.url + "/reject/" + id).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.checkEmail = function (email) {
        return this.http.post(this.url + "/check-email/" + email, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.searchCompanyByName = function (name) {
        return this.http.post(this.url + "/search-company-by-name/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.getCompanyByNZBN = function (name) {
        return this.http.post(this.url + "/get-company-by-nzbn/" + name, '').pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updatePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.addPerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.deletePerson = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/delete-person/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateStore = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-store/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.addAccount = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/add-account/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateOnboardStatus = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-onboard-status/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateOnboardContactInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/update-contact-info/" + id, value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateV3OnboardCompanyInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + id + "/update-company-info", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateV3contactInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + id + "/update-contact-info", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateV3SettlementAccountInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + id + "/update-settlement-account", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.updateOnboardCompanyInfo = function (id, value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/" + id + "/update-company-info", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.checkCompanyInfo = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/check-company-number", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.manualSignUp = function (value, token) {
        var header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('Authorization', token);
        var options = new RequestOptions({ headers: header });
        return this.httpP.post(this.url + "/sign-up", value, options).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    OrgNewOnboardService.prototype.getAllNewOnboarding = function (searchCondtion, token, paging, userId) {
        var userIdStr = '&adminUserId=' + userId;
        return this.http.get(this.url + "?" + paging.getPagingStr() + (searchCondtion + userIdStr) + this.embeddedStr).pipe(map(function (response) { return response; }), catchError(function (res) { return observableThrowError(res); }));
    };
    return OrgNewOnboardService;
}(BaseService));
export { OrgNewOnboardService };
